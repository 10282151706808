import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import NotSupportBrowser from "@/views/errors/NotSupportBrowser.vue";
import NotFound from "@/views/errors/NotFound.vue";
import Home from "@/views/Home.vue";
import Logout from "@/views/Logout.vue";
import core from "@/core";
import store from "@/store";
import Login from "@/views/Login.vue";
import Constant from "@/store/constant";
import Estimate from "@/views/Estimate.vue";
import EstimatePrint from "@/views/EstimatePrint.vue";
import Pay from "@/views/Pay.vue";
import SpecialPrice from "@/views/SpecialPrice.vue";
import Status from "@/views/Status.vue";
import StatusView from "@/views/StatusView.vue";
import Support from "@/views/Support.vue";
import Review from "@/views/Review.vue";
import Roulette from "@/views/Roulette.vue";

Vue.use(VueRouter);

const appName = Constant.appName;

export enum MenuType {
  NAVIGATION = "navigation",
  BACK = "back",
  NONE = "none",
}

export interface SubMenu {
  text: SubTextMenu | null | undefined;
  icon: SubIconMenu | null | undefined;
}

export interface SubIconMenu {
  visible: boolean;
  list: Menu[] | null;
}

export interface SubTextMenu {
  visible: boolean;
  width: number;
  list: Menu[] | null;
}

export interface Menu {
  icon: string;
  text: string;
  id: string;
  visible: boolean;
  event: boolean;
}

export interface IRouteMeta {
  title: string;
  roles: string[] | null;
  menuType: MenuType;
  pageStack: boolean;
  subMenu: SubMenu | null;
}

export class RouteMeta implements IRouteMeta {
  title: string;
  roles: string[] | null;
  menuType: MenuType;
  pageStack: boolean;
  subMenu: SubMenu | null;

  constructor(params: IRouteMeta) {
    this.title = params.title;
    this.roles = params.roles;
    this.menuType = params.menuType;
    this.pageStack = params.pageStack;
    this.subMenu = params.subMenu;
  }
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: new RouteMeta({
      title: "",
      roles: ["customer"],
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
    }),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: new RouteMeta({
      title: "로그인",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
    }),
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: new RouteMeta({
      title: "로그아웃",
      roles: ["customer"],
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
    }),
  },
  {
    path: "/estimate",
    name: "estimate",
    component: Estimate,
    meta: new RouteMeta({
      title: "견적서",
      roles: ["customer"],
      menuType: MenuType.BACK,
      pageStack: true,
      subMenu: null,
    }),
  },
  {
    path: "/estimate/print",
    name: "estimatePrint",
    component: EstimatePrint,
    meta: new RouteMeta({
      title: "견적서 출력",
      roles: ["customer"],
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
    }),
  },
  {
    path: "/pay",
    name: "pay",
    component: Pay,
    meta: new RouteMeta({
      title: "결제",
      roles: ["customer"],
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
    }),
  },
  {
    path: "/special-price",
    name: "specialPrice",
    component: SpecialPrice,
    meta: new RouteMeta({
      title: "이벤트 특가표",
      roles: ["customer"],
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
    }),
  },
  {
    path: "/roulette",
    name: "roulette",
    component: Roulette,
    meta: new RouteMeta({
      title: "룰렛 이벤트",
      roles: ["customer"],
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
    }),
  },
  {
    path: "/status",
    name: "status",
    component: Status,
    meta: new RouteMeta({
      title: "시공현황",
      roles: ["customer"],
      menuType: MenuType.BACK,
      pageStack: true,
      subMenu: null,
    }),
  },
  {
    path: "/status/:id(\\d+)",
    name: "statusView",
    component: StatusView,
    meta: new RouteMeta({
      title: "시공현황상세",
      roles: ["customer"],
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: {
        icon: {
          visible: true,
          list: [
            {
              id: "download",
              icon: "mdi-download",
              text: "이미지 전체 다운로드",
              visible: true,
              event: false,
            },
          ],
        },
        text: null,
      },
    }),
  },
  {
    path: "/support",
    name: "support",
    component: Support,
    meta: new RouteMeta({
      title: "고객지원",
      roles: ["customer"],
      menuType: MenuType.BACK,
      pageStack: true,
      subMenu: null,
    }),
  },
  {
    path: "/review",
    name: "review",
    component: Review,
    meta: new RouteMeta({
      title: "고객후기",
      roles: ["customer"],
      menuType: MenuType.BACK,
      pageStack: true,
      subMenu: null,
    }),
  },
  {
    path: "/error/not-support-browser",
    name: "notSupportBrowser",
    component: NotSupportBrowser,
    meta: new RouteMeta({
      title: "",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
    }),
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: new RouteMeta({
      title: "",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
    }),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routeMap = new Map();
routes.forEach((data) => {
  if (data.children != null) {
    const path = data.path;
    data.children.forEach((data) => {
      routeMap.set(path + data.path, data);
    });
  } else {
    routeMap.set(data.path, data);
  }
});

function routerResult(next, path: string) {
  next({ path: path });
  const route = routeMap.get(path);
  return route.meta;
}

async function beforeEach(to, from, next) {
  const state = store.state;
  if (state.auth.user) {
    // 로그인 중인 사용자
    if (to.meta.roles != null) {
      // 로그인 사용자 접근 가능 페이지일 경우 이동
      next();
    } else {
      // 비로그인 사용자 접근 가능 페이지일 경우 이동
      if (to.path.toLowerCase() === "/login") {
        if (core.utils.validate.isBlank(to.query.v)) {
          // 로그인중인 사용자가 로그인 페이지 접근시 이동
          return routerResult(next, "/");
        } else {
          // 로그인중인 사용자가 자동인증 정보파라미터를 포함해서 들어왔을 경우 로그인 페이지로 이동
          next();
        }
      } else {
        next();
      }
    }
  } else {
    // 비로그인 중인 사용자 세션 확인
    const user = await store.getters["auth/user"]();
    if (user != null) {
      // 세션이 존재하면 로그인중인 사용자로 변경되어 재호출 처리
      return await beforeEach(to, from, next);
    } else {
      if (to.meta.roles != null) {
        // 로그인 사용자 접근 가능 페이지일 경우 메인 페이지로 이동
        return routerResult(next, "/login");
      } else {
        next();
      }
    }
  }

  return to.meta;
}

// let first = true;
router.beforeEach(async (to, from, next) => {
  // console.log("from   : ", from);
  // console.log("to     : ", to);

  if (store.state.app.printClass) {
    await store.dispatch("app/clearPrint");
  }

  if (
    (from.path === "/home" && to.path === "/login") ||
    (from.path === "/login" && to.path === "/login")
  ) {
    window.history.back();
    return;
  }

  // if (first) {
  //   first = false;
  //   if (core.utils.redirectWebviewBrowser(router)) {
  //     return;
  //   }
  // }

  // console.log("from " + from.path + " -> to : " + to.path);
  const meta = await beforeEach(to, from, next);
  if (meta != null && meta.title != null) {
    if (meta.title.length > 0) {
      document.title = `${appName} - ${meta.title}`;
    } else {
      document.title = appName;
    }
  }

  if (meta != null && meta.menuType !== MenuType.NONE) {
    await store.dispatch("topToolbar/changeTitle", meta.title);
  }
});

export default router;
